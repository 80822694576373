import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  first,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AdminService } from '@services/admin.service';
import { adminAuthorisation } from '@models/admin-models';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { PagedData } from '@admin/finance/finance.service';
import { Observable } from 'rxjs';

const api = 'api/arrangements';

export interface ArrangementTableItem {
  id: number;
  name?: string;
  email?: string;
  rec_no?: number;
  arrangement: any;
}

@Injectable({
  providedIn: 'root',
})
export class ArrangementService {
  sendBill(id: number): any {
    return this.clubs.singleClub.pipe(
      mergeMap((club) => this.http.get(`${api}/${club.club_id}/${id}/email`))
    );
  }

  constructor(private http: HttpClient, private clubs: AdminService) {
    console.log('arrangement service');
  }

  delete(id: number): any {
    return this.clubs.singleClub.pipe(
      mergeMap((club) => this.http.delete(`${api}/${club.club_id}/${id}`))
    );
  }

  update(id: number, arrangement: ArrangementTableItem): any {
    return this.clubs.singleClub.pipe(
      mergeMap((club) =>
        this.http.patch(`${api}/${club.club_id}/${id}`, arrangement)
      )
    );
  }

  list() {
    return this.clubs.activeClub.pipe(
      mergeMap(({ club_id }) =>
        this.http.get<ArrangementTableItem[]>(`${api}/${club_id}`)
      )
    );
  }

  removeSchedule(memberId: number): any {
    return this.clubs.singleClub.pipe(
      mergeMap(({ club_id }) =>
        this.http.delete(`${api}/${club_id}/${memberId}/schedule`)
      )
    );
  }

  createSchedule(id: number, arrangement: { [key: string]: any }): any {
    return this.clubs.singleClub.pipe(
      mergeMap(({ club_id }) =>
        this.http.post(`${api}/${club_id}/${id}/schedule`, arrangement)
      )
    );
  }

  getPagedArrangements({
    pager,
    sorter,
    filter,
  }: {
    pager: PageEvent | undefined;
    sorter: Sort;
    filter: string | null | undefined;
  }): Observable<PagedData<ArrangementTableItem>> {
    const params: {
      [param: string]:
        | string
        | number
        | boolean
        | ReadonlyArray<string | number | boolean>;
    } =
      pager != null
        ? { pageSize: pager.pageSize, pageIndex: pager.pageIndex }
        : {};
    if (sorter.direction != '') {
      params['sortBy'] = sorter.active;
      params['direction'] = sorter.direction;
    }
    return this.clubs.activeClub.pipe(
      switchMap(({ club_id }) =>
        this.http.get<PagedData<ArrangementTableItem>>(`${api}/${club_id}`, {
          params,
        })
      )
    );
  }
}
